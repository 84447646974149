/** @jsxImportSource @emotion/react */

import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CustomProperties, Var } from './CssCustomProperties';
import { Header } from './Header';
import { Main } from './Main';
import { Nav } from './Nav';

const queryClient = new QueryClient();

export const App = () => {
    useEffect(() => {
        if (process.env.NODE_ENV !== 'production') {
            document.title = 'MMP - Dev';
        }
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <div
                css={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: '4rem',
                    '--nav-height': '4rem',
                    [CustomProperties.PrimaryFont]: 'Galdeano',
                    [CustomProperties.PrimaryColor]: 'lavender',
                    [CustomProperties.PrimaryBackground]: '#a6a6ba',
                    color: Var.PrimaryColor,
                    '> main': {
                        marginTop: '-.25rem',
                    },
                    header: {
                        order: 2,
                        marginTop: 'var(--nav-height)',
                    },
                    nav: {
                        order: 1,
                        height: 'var(--nav-height)',
                        position: 'fixed',
                        width: '100%',
                        left: 0,
                        top: 0,
                        backgroundColor: 'rgb(83, 101, 87)',
                    },
                    main: {
                        order: 3,
                    },
                }}
            >
                <Header />
                <Nav />
                <Main />
            </div>
        </QueryClientProvider>
    );
};
