export enum CustomProperties {
    PrimaryFont = '--primary-font',
    PrimaryColor = '--primary-color',
    PrimaryBackground = '--primary-background'
}

export const Var = {
    PrimaryFont: `var(${CustomProperties.PrimaryFont})`,
    PrimaryColor: `var(${CustomProperties.PrimaryColor})`,
    PrimaryBackground: `var(${CustomProperties.PrimaryBackground})`,
}