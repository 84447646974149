/** @jsxImportSource @emotion/react */

import { useEffect, useRef, useState } from 'react';
import { Var } from './CssCustomProperties';
import img6274 from './static/IMG_6274_thumb.jpg';

export const GalleryCard = () => {
    const thumbs = useRef([
        'IMG_6274',
        'IMG_6275',
        'IMG_6276',
        'IMG_6277',
        'IMG_6278',
        'IMG_6279',
        'IMG_6280',
        'IMG_6281',
        'IMG_6282',
        'IMG_6283',
        'IMG_6284',
        'IMG_6285',
        'IMG_6286',
        'IMG_6287',
        'IMG_6288',
        'IMG_6289',
        'IMG_6290',
    ]);

    const [currentThumb, setCurrentThumb] = useState(0);
    const [imageSrcMap, setImageSrcMap] = useState<{ [index: string]: string }>({ [`${thumbs.current[0]}`]: img6274 });
    const imageSrcMapRef = useRef(imageSrcMap);
    imageSrcMapRef.current = imageSrcMap;

    useEffect(() => {
        const timer = setTimeout(() => {
            setCurrentThumb((previous) => (previous + 1 > thumbs.current.length - 1 ? 0 : previous + 1));
        }, 4000);
        return () => {
            clearTimeout(timer);
        };
    });

    useEffect(() => {
        const imageName = thumbs.current[currentThumb];
        const importImage = async () => {
            const imageSrc = await import(`./static/${imageName}_thumb.jpg`);
            console.log(imageSrc.default);
            setImageSrcMap((previous) => ({
                ...previous,
                [imageName]: imageSrc.default,
            }));
        };
        const imageSrc = imageSrcMapRef.current[imageName];
        if (imageSrc === undefined) {
            importImage();
        }
    }, [currentThumb]);

    return (
        <div
            css={{
                display: 'flex',
                justifyContent: 'center',
                color: Var.PrimaryColor,
                backgroundColor: 'rgb(83, 101, 87)',
                boxShadow: '0px 0px 10px 0px rgba(18, 11, 18, 0.75)',
                textDecoration: 'none',
                '> * + *': {
                    marginTop: '1rem',
                },
                ':visited': {
                    color: Var.PrimaryColor,
                    textDecoration: 'none',
                },
            }}
        >
            <img
                //srcSet={`${banner_1200} 1960w, ${banner_960} 960w, ${banner_480} 480w`}
                src={imageSrcMap[thumbs.current[currentThumb]] ?? imageSrcMap[thumbs.current[currentThumb - 1]]}
                alt={'Misty Mountain Potters Gallery'}
                css={{}}
            />
        </div>
    );
};
