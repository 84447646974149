/** @jsxImportSource @emotion/react */

import { Var } from './CssCustomProperties';
import instagramLogo from './static/instagram.svg';

export const Nav = () => {
    return (
        <nav
            css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '0px 0px 10px 0px rgba(18, 11, 18, 0.75)',
                '> * + *': {
                    marginLeft: '2rem',
                },
                '> :last-child': {
                    position: 'absolute',
                    top: 'calc(50% - .5rem)',
                    right: '2rem',
                },
                a: {
                    fontFamily: Var.PrimaryFont,
                    fontSize: '1rem',
                    color: 'lavender',
                },
            }}
        >
            <div>
                {/* <a href={'https://www.etsy.com/shop/MistyMountainPotters'} target={'_blank'} rel={'noopener noreferrer'}>
                    {'Shop'}{' '}
                </a> */}
            </div>
            {/* <div>
                <a href={'#gallery'}>{'Gallery'}</a>
            </div>
            <div>
                <a href={'#about-us'}>{'About Us'}</a>
            </div> */}
            <div
                css={{
                    '> * + *': {
                        marginLeft: '1rem',
                    },
                }}
            >
                {/* <button
                    css={{
                        fontFamily: Var.PrimaryFont,
                        fontSize: '1rem',
                        border: 'none',
                        background: 'none',
                        color: Var.PrimaryColor,
                    }}
                >
                    {'Newsletter/E-mail'}
                </button> */}
                <a href={'https://www.instagram.com/misty.mountain.potters/'} target={'_blank'} rel={'noopener noreferrer'} css={{ verticalAlign: 'middle' }}>
                    <img
                        src={instagramLogo}
                        alt={'Misty Mountain Potters Instagram'}
                        css={{
                            height: '1rem',
                            width: '1rem',
                        }}
                    />
                </a>
            </div>
        </nav>
    );
};
