/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { Button, Input, unstable_useId as useId } from 'reakit';
import { Var } from './CssCustomProperties';

export const NewsletterCard = () => {
    const [email, setEmail] = useState('');
    const [signupComplete, setSignupComplete] = useState(false);
    const { id: emailInputId } = useId();

    const {
        mutate,
        isLoading: isSigningUp,
        isError,
    } = useMutation<void, Error, string>(async (email: string) => {
        await fetch(
            process.env.NODE_ENV === 'production' ? `https://${window.location.hostname}/api/newsletter-signup` : `https://${window.location.hostname}:5001/api/newsletter-signup`,
            {
                body: JSON.stringify(email),
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            }
        );
    });

    return (
        <div
            css={{
                display: 'flex',
                backgroundColor: 'rgb(83, 101, 87)',
                boxShadow: '0px 0px 10px 0px rgba(18, 11, 18, 0.75)',
                '> :last-child': {
                    flexGrow: 1,
                },
            }}
        >
            <div
                css={{
                    borderRight: `2px dashed #AA99AA`, //${Var.PrimaryColor}`,
                    width: '52%',
                }}
            >
                <div
                    css={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '.5rem 1.5rem',
                        borderBottom: `2px dashed #AA99AA`, //${Var.PrimaryColor}`,
                        height: '4rem',
                    }}
                >
                    <h2
                        css={{
                            marginBlock: 0,
                            margin: 0,
                            fontFamily: Var.PrimaryFont,
                            fontSize: '2.75rem',
                            '@media screen and (max-width: 480px)': {
                                fontSize: '1.25rem',
                            },
                            '@media screen and (max-width: 400px)': {
                                fontSize: '1.25rem',
                            },
                            '@media screen and (max-width: 320px)': {
                                fontSize: '1.25rem',
                            },
                        }}
                    >
                        {'Newsletter Sign-up'}
                    </h2>
                </div>
                <div
                    css={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '1rem 0',
                    }}
                >
                    <p
                        css={{
                            marginBlock: 0,
                            margin: 0,
                            width: '70%',
                            fontSize: '1.25rem',
                            textAlign: 'center',
                            '@media screen and (max-width: 480px)': {
                                fontSize: '1rem',
                            },
                            '@media screen and (max-width: 400px)': {
                                fontSize: '.8rem',
                            },
                            '@media screen and (max-width: 320px)': {
                                fontSize: '.75rem',
                            },
                        }}
                    >
                        {'Sign-up to hear about future studio updates.'}
                    </p>
                </div>
            </div>
            <div
                css={{
                    display: 'flex',
                    flexDirection: 'column',
                    '> * + *': {
                        marginTop: '1rem',
                    },
                    '> :last-child': {
                        alignSelf: 'flex-end',
                        marginBottom: '1rem',
                        marginRight: '1rem',
                    },
                }}
            >
                <div
                    css={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0 2rem',
                        flexGrow: 1,
                        '> :last-child': {
                            flexGrow: 1,
                            marginLeft: '1rem',
                        },
                        '@media screen and (max-width: 480px)': {
                            flexDirection: 'column',
                            padding: '1rem 1rem',
                            '> :last-child': {
                                marginLeft: '0',
                                marginTop: '.5rem',
                            },
                        },
                    }}
                >
                    <label
                        css={{
                            fontFamily: Var.PrimaryFont,
                            fontSize: '1.5rem',
                            whiteSpace: 'nowrap',
                            '@media screen and (max-width: 480px)': {
                                fontSize: '1rem',
                                alignSelf: 'flex-start',
                            },
                        }}
                        aria-label={'E-Mail'}
                        htmlFor={emailInputId}
                    >
                        {'E-Mail:'}
                    </label>
                    <Input
                        id={emailInputId}
                        type={'email'}
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        css={{
                            height: '32px',
                            fontSize: '1rem',
                            borderRadius: '.25rem',
                            border: '1px solid transparent',
                            width: '100%',
                            color: '#221122',
                            ':hover, :focus': {
                                border: '1px solid #221122',
                            },
                            ':focus': {
                                outline: 'none',
                            },
                            '@media screen and (max-width: 480px)': {
                                height: '24px',
                                fontSize: '.75rem',
                            },
                        }}
                    />
                </div>
                {signupComplete && (
                    <div
                        css={{
                            marginTop: 0,
                            marginLeft: '2rem',
                        }}
                    >
                        <span>{'Newsletter Sign-up Complete'}</span>
                    </div>
                )}
                {isSigningUp ? (
                    <div
                        css={{
                            marginTop: 0,
                            marginLeft: '2rem',
                        }}
                    >
                        <span>{'Signing-up for Newsletter'}</span>
                    </div>
                ) : isError ? (
                    <div
                        css={{
                            marginTop: 0,
                            marginLeft: '2rem',
                        }}
                    >
                        <span>{'An error occurred during sign-up'}</span>
                    </div>
                ) : null}
                <Button
                    onClick={() => {
                        mutate(email, {
                            onSuccess: () => {
                                setEmail('');
                                setSignupComplete(true);
                            },
                        });
                    }}
                    disabled={isSigningUp}
                    css={{
                        height: '2rem',
                        padding: '0 .75rem',
                        fontFamily: Var.PrimaryFont,
                        fontSize: '1.25rem',
                        lineHeight: '2rem',
                        borderRadius: '.25rem',
                        backgroundColor: Var.PrimaryBackground,
                        color: '#221122',
                        border: 'none',
                        ':hover': {
                            backgroundColor: Var.PrimaryColor,
                        },
                        '@media screen and (max-width: 480px)': {
                            height: '1.75rem',
                            lineHeight: '1.75rem',
                            fontSize: '1rem',
                        },
                    }}
                >
                    {'Submit'}
                </Button>
            </div>
        </div>
    );
};
