/** @jsxImportSource @emotion/react */

import React from 'react';
import { Var } from './CssCustomProperties';
import { GalleryCard } from './GalleryCard';
import { NewsletterCard } from './NewsletterCard';

export const Main = () => {
    return (
        <main
            css={{
                color: Var.PrimaryColor,
                '> * + *': {
                    marginTop: '2rem',
                },
            }}
        >
            <div
                css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0 .5rem',
                    '> *': {
                        height: '12.5rem',
                    },
                    '> :first-child': {
                        width: '33%',
                    },
                    '> :last-child': {
                        width: '66%',
                    },
                    '> * + *': {
                        marginLeft: '2rem',
                    },
                    '@media screen and (max-width: 480px)': {
                        flexDirection: 'column',
                        '> :first-child': {
                            width: '98%',
                        },
                        '> :last-child': {
                            width: '98%',
                        },
                        '> * + *': {
                            marginLeft: '0',
                            marginTop: '1rem',
                        },
                    },
                }}
            >
                {/* <ShopCard /> */}
                <GalleryCard />
                <NewsletterCard />
            </div>
            {/* <Gallery />
            <AboutUs /> */}
        </main>
    );
};
