/** @jsxImportSource @emotion/react */
import { Var } from './CssCustomProperties';
import banner_1200 from './static/banner_1200.png';
import banner_480 from './static/banner_480.png';
import banner_480_320w from './static/banner_480_320w.png';

export const Header = () => {
    return (
        <header
            css={{
                position: 'relative',
            }}
        >
            <picture>
                <source media={'(max-width:480px)'} srcSet={`${banner_480_320w} 320w, ${banner_480} 480w`} />
                <img
                    //srcSet={`${banner_1200} 1960w, ${banner_960} 960w, ${banner_480} 480w`}
                    src={banner_1200}
                    alt={'Misty Mountain Potters'}
                    css={{
                        boxShadow: '0px 0px 10px 0px rgba(18, 11, 18, 0.75)',
                    }}
                />
            </picture>
            <div
                css={{
                    position: 'absolute',
                    margin: '0 auto',
                    paddingTop: '3rem',
                    top: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '@media screen and (max-width: 400px)': {
                        paddingTop: '2rem',
                    },
                }}
            >
                <h1
                    css={{
                        marginBlock: 0,
                        margin: 0,
                        fontFamily: 'Cabin Sketch',
                        fontSize: '6rem',
                        fontWeight: 'normal',
                        lineHeight: '10rem',
                        color: '#FFFFFF',
                        textAlign: 'center',
                        width: '100%',
                        background: 'linear-gradient(0deg, #33333300 0%, #33443366 40%,#33443366 60%, #33333300 100%)',
                        '@media screen and (max-width: 480px)': {
                            fontSize: '3rem',
                            lineHeight: '5rem',
                        },
                        '@media screen and (max-width: 400px)': {
                            fontSize: '2.5rem',
                            lineHeight: '3.25rem',
                        },
                        '@media screen and (max-width: 320px)': {
                            fontSize: '2rem',
                            lineHeight: '3rem',
                        },
                    }}
                >
                    {'Misty Mountain Potters'}
                </h1>
                <p
                    css={{
                        marginBlock: 0,
                        margin: 0,
                        fontFamily: Var.PrimaryFont,
                        fontSize: '2rem',
                        color: '#FFFFFF',
                        width: '60%',
                        textAlign: 'center',
                        '@media screen and (max-width: 480px)': {
                            fontSize: '1rem',
                        },
                        '@media screen and (max-width: 400px)': {
                            fontSize: '.8rem',
                        },
                        '@media screen and (max-width: 320px)': {
                            fontSize: '.6rem',
                        },
                    }}
                >
                    {'A whimsical/functional pottery studio grounded in centering, loving & being'}
                </p>
            </div>
        </header>
    );
};
